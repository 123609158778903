<template>
  <div>
    <EditEvent
      :singleEvent="singleEvent"
      :selectedRoomTeam="selectedRoomTeam"
      v-if="currentIsAdmin == '1' && currentTeamIsActive == 1"
      :currentUserId="currentUserId"
      :currentIsAdmin="currentIsAdmin"
      :currentTeamIsActive="currentTeamIsActive"
      :editEventOption="editEventOption"
    />
    <PrintRoasterModal
      :users="eventGoingForpdf"
      :InvitedList="eventInvitedList"
      :fileName="singleEvent.eventName"
    />
    <div class="order-history single-box-score-loader" v-if="showLoader == 1">
      <div class="lds-dual-ring-media"></div>
    </div>
    <div v-if="showLoader == 0">
      <div class="event-sec-top sec-top">
        <div class="left-part">
          <div
            class="content"
            v-if="
              singleEvent.exactStartDate != '' &&
              singleEvent.exactStartTime != '' &&
              singleEvent.exactEndTime != ''
            "
          >
            <span v-if="singleEvent.exactStartDate == singleEvent.exactEndDate">
              {{ singleEvent.exactStartDate }}
            </span>
            <span v-else
              >{{ singleEvent.exactStartDate.split(",")[0] }} to
              {{ singleEvent.exactEndDate }}</span
            >
            <span v-if="singleEvent.time_zone !== null">
              ({{ singleEvent.time_zone.split(")").pop() }})
            </span>
          </div>
          <h3 class="text-capitalize">
            {{ singleEvent.eventName }}
          </h3>
          <p class="single-asso-type">
            <span v-if="singleEvent.association">
              {{ singleEvent.association }} -
            </span>
            {{ singleEvent.eventType }}
          </p>
          <router-link :to="`/my-team/events/${selectedRoomTeam.id}`">
            {{ selectedRoomTeam.teamName }}
          </router-link>
        </div>
        <div class="right-part">
          <div class="d-flex flex-column align-items-end justify-content-end">
            <SingleEventDetailPlay
              v-if="selectedRoomTeam.teamType == 'sports'"
              :singleEvent="singleEvent"
            />
            <div class="right-part-btns mt-2">
              <div class="print-list" v-if="currentIsAdmin == '1' && currentTeamIsActive == 1" >
                <ul>
                  <li v-if="selectedRoomTeam.teamType == 'sports'">
                    <a tabindex="0" class="p-0 m-0" @click="callRosterModal()"
                      ><img src="../../assets/Svg/printer.svg" alt="" /><span
                        >Roster</span
                      ></a
                    >
                    <a v-if="showEventMemeberCountShimmer == 1">
                      <content-placeholders :rounded="true">
                        <content-placeholders-text
                          :lines="1"
                          class="print-roaster-btn content-margin-reaction width-40"
                        />
                      </content-placeholders>
                    </a>
                  </li>
                  <li>
                    <!-- <a @click="printTravelDetails" class="p-0" -->
                    <a @click="generateTravelExcelFile()" class="p-0"
                      ><img src="../../assets/Svg/printer.svg" alt="" /><span
                        >Travel</span
                      ></a
                    >
                  </li>
                </ul>
              </div>
              <div
                class="icon-btn"
                v-if="currentIsAdmin == '1' && currentTeamIsActive == 1"
                @click="openEditEventOption"
                v-click-outside="onClickOutside"
              >
                <img src="../../assets/Svg/ellipse.svg" alt="" />
                <div
                  class="sub-setting-options edit-event-options"
                  v-if="editEventOption"
                >
                  <ul class="d-block pb-0">
                    <li @click.stop="callModal()" class="cursor-pointer">
                      Edit Event
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="game-info-sec row">
        <div class="col-12 col-lg-8 p-0" v-if="selectedRoomTeam.teamType == 'sports'">
          <div class="game-content-wrap" v-if="selectedRoomTeam.teamType == 'sports'">
            <div class="d-flex justify-content-between flex-wrap align-items-center" style="gap: 5px;">
              <div class="left-part-btns">
                <button
                  :class="{ active: selectedFilter === 'all' }"
                  @click="filterGames('all')"
                >
                  All
                </button>
                <button
                  :class="{ active: selectedFilter === 'ongoing' }"
                  @click="filterGames('ongoing')"
                >
                  Ongoing
                </button>
                <button
                  :class="{ active: selectedFilter === 'final' }"
                  @click="filterGames('final')"
                >
                  Final
                </button>
              </div>
              <div class="game-details">
                <NewGame
                  v-if="
                    currentIsAdmin == '1' &&
                    selectedRoomTeam.teamType == 'sports' &&
                    currentTeamIsActive == 1
                  "
                  :selectedRoomTeam="selectedRoomTeam"
                  :singleEvent="singleEvent"
                />
              </div>
            </div>

            <div>
              <GameInfo
                :selectedRoomTeam="selectedRoomTeam"
                :currentIsAdmin="currentIsAdmin"
                :games="filteredGames"
                :showGameLoader="showGameLoader"
                :eventAlertOptions="eventAlertOptions"
                :currentTeamIsActive="currentTeamIsActive"
              />
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4 p-0 pl-lg-3 mt-3 mt-lg-0">
          <div class="location-attendee-wrap">
            <div class="attendee-wrap-content">
              <AttendeesInfo
                :selectedRoomTeam="selectedRoomTeam"
                :currentUserId="currentUserId"
                :currentIsAdmin="currentIsAdmin"
                :singleEvent="singleEvent"
              />
            </div>
            <div class="location-wrap-content">
              <h6>Location</h6>
              <div class="event-list-details event-info-wrap">
                <p
                  @click="openGoogleMap(singleEvent)"
                  class="location-content"
                  v-if="
                    (singleEvent.status == 1 &&
                      singleEvent.address != null &&
                      singleEvent.address != '' &&
                      singleEvent.address != 'null') ||
                    (singleEvent.city != null &&
                      singleEvent.city != '' &&
                      singleEvent.city != 'null') ||
                    (singleEvent.state != null &&
                      singleEvent.state != '' &&
                      singleEvent.state != 'null')
                  "
                >
                  <img src="../../assets/Svg/location.svg" />
                  <span
                    >{{ singleEvent.address }} {{ singleEvent.city }},
                    {{ singleEvent.state }}</span
                  >
                </p>
                <span class="location-content" v-if="singleEvent.status == 2">
                  <img src="../../assets/images/link.png" />
                  <a
                    :href="singleEvent.Url"
                    target="_blank"
                    class="mr-0 font-weight-normal"
                  >
                    {{ singleEvent.Url }}
                  </a>
                </span>
                <div class="weather-sec">
                  <WeatherData
                    v-if="isEventWithinFiveDays(singleEvent)"
                    :eventId="singleEvent.id"
                  />
                </div>
                <div class="row no-gutters">
                  <div class="col-12">
                    <google-map
                      :center="marker.position"
                      :zoom="15"
                      map-style-id="roadmap"
                      :options="mapOptions"
                      style="width: 100%; height: 45vmin"
                      ref="mapRef"
                      id="map"
                      @click="handleMapClick"
                    >
                      <google-maps-marker
                        :position="marker.position"
                        :clickable="true"
                        :draggable="true"
                        @drag="handleThrottledMarkerDrag"
                        @click="panToMarker"
                      ></google-maps-marker>
                    </google-map>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-observe-visibility="handleScrollToBottom" v-if="games.length"></div>
  </div>
</template>

<script>
import axios from "axios";
import AttendeesInfo from "./AttendeesInfo.vue";
import GameInfo from "./GameInfo.vue";
import EditEvent from "../Teams/ChatRight/ChatEvents/EditEvent.vue";
import NewGame from "../Teams/Games/NewGame.vue";
import SingleEventDetailPlay from "./SingleEventDetailPlay.vue";
import * as firestoreService from "../../database/firestore";
import PrintRoasterModal from "../Teams/ChatRight/ChatEvents/PrintRoasterModal.vue";
import WeatherData from "@/components/NewGameTime/Events/WeatherData.vue";
import ExcelJS from "exceljs";
import _ from "lodash";

export default {
  components: {
    AttendeesInfo,
    EditEvent,
    GameInfo,
    SingleEventDetailPlay,
    NewGame,
    PrintRoasterModal,
    WeatherData,
  },
  name: "EventDetail",
  data() {
    return {
      selectedFilter: "all", // Default filter
      filteredGames: [],
      isActiveGames: true,
      isActiveAttendees: false,
      teamId: "",
      currentIsAdmin: "0",
      currentIsFan: "0",
      currentTeamIsActive: 1,
      selectedRoomTeam: {},
      currentUserId: "",
      userId: localStorage.getItem("userId"),
      allUsers: [],
      games: [],
      page: 1,
      lastPage: 1,
      size: 5,
      showLoader: 1,
      singleEvent: {},
      showGameLoader: false,
      loadingMoreGames: false,
      eventAlertOptions: [],
      editEventOption: false,
      eventGoingForExcel: [],
      eventGoingForpdf: [],
      eventInvitedList: [],
      showEventMemeberCountShimmer: 0,

      marker: { position: { lat: 10, lng: 10 } },
      center: { lat: 10, lng: 10 },
      mapOptions: {
        disableDefaultUI: false,
      },
    };
  },
  watch: {
    games: {
      immediate: true,
      handler(newGames) {
        this.filterGames(this.selectedFilter, newGames);
      },
    },
  },
  // computed: {
  //   filteredGames() {
  //     if (this.selectedFilter === "ongoing") {
  //       return this.games.filter(game => game.status == 1);
  //     } else if (this.selectedFilter === "final") {
  //       return this.games.filter(game => game.status == 2);
  //     }
  //     console.log(this.games)
  //     return this.games; // Default: Show all games
  //   }
  // },
  methods: {
    filterGames(filter, gameList = this.games) {
      this.selectedFilter = filter;
      console.log(filter);
      if (filter === "ongoing") {
        this.filteredGames = gameList.filter((game) => game.game_live == 1 && game.status !== 2);
        console.log("ongoing", this.filteredGames);
      } else if (filter === "final") {
        this.filteredGames = gameList.filter((game) => game.status == 2);
        console.log("final", this.filteredGames);
      } else {
        this.filteredGames = gameList; // Show all games
      }
    },
    handleThrottledMarkerDrag: _.throttle(function (event) {
      this.handleMarkerDrag(event);
    }, 50),
    //sets the position of marker when dragged
    handleMarkerDrag(e) {
      window.test = this;
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      var geocoder = new window.google.maps.Geocoder();
      var latlng = this.marker.position;
      geocoder.geocode({ location: latlng }, function (results, status) {
        if (status === "OK") {
          if (results[1]) {
            for (let i = 0; i < results.length; i++) {
              // eslint-disable-next-line
              window.test.location = results[2].formatted_address;
              if (results[i]?.types[0] == "locality") {
                for (let j = 0; j < results[i].address_components.length; j++) {
                  if (
                    results[i].address_components[j]?.types[0] == "locality"
                  ) {
                    window.test.city =
                      results[i].address_components[j].long_name;
                  }
                }
                // this.city = results[i].long_name;
              }
              if (
                results[i]?.types[0] == "premise" ||
                results[i]?.types[0] == "street_address" ||
                results[i]?.types[0] == "route" ||
                results[i]?.types[0] == "neighborhood"
              ) {
                for (let j = 0; j < results[i].address_components.length; j++) {
                  if (
                    results[i].address_components[j]?.types[0] == "route" ||
                    results[i].address_components[j]?.types[0] ==
                      "street_address" ||
                    results[i].address_components[j]?.types[0] == "premise"
                  ) {
                    window.test.street =
                      results[i].address_components[j].long_name;
                  }
                }
              }
              if (results[i]?.types[0] == "administrative_area_level_1") {
                for (let j = 0; j < results[i].address_components.length; j++) {
                  if (
                    results[i].address_components[j]?.types[0] ==
                    "administrative_area_level_1"
                  ) {
                    window.test.state =
                      results[i].address_components[j].long_name;
                  }
                }
              }
              if (results[i]?.types[0] == "postal_code") {
                for (let j = 0; j < results[i].address_components.length; j++) {
                  if (
                    results[i].address_components[j]?.types[0] == "postal_code"
                  ) {
                    window.test.zipCode =
                      results[i].address_components[j].long_name;
                  }
                }
              }
            }
          } else {
            window.alert("No results found");
          }
        }
      });
    },
    panToMarker() {
      console.log(this.marker.position);
      this.$refs.mapRef.panTo(this.marker.position);
    },
    //Moves the marker to click position on the map
    handleMapClick(e) {
      window.test = this;
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      var geocoder = new window.google.maps.Geocoder();
      var latlng = this.marker.position;
      geocoder.geocode({ location: latlng }, function (results, status) {
        if (status === "OK") {
          if (results[0]) {
            for (let i = 0; i < results.length; i++) {
              // eslint-disable-next-line
              window.test.location = results[2].formatted_address;
              if (results[i]?.types[0] == "locality") {
                for (let j = 0; j < results[i].address_components.length; j++) {
                  if (
                    results[i].address_components[j]?.types[0] == "locality"
                  ) {
                    window.test.city =
                      results[i].address_components[j].long_name;
                  }
                }
                // this.city = results[i].long_name;
              }
              if (
                results[i]?.types[0] == "premise" ||
                results[i]?.types[0] == "street_address" ||
                results[i]?.types[0] == "route"
              ) {
                for (let j = 0; j < results[i].address_components.length; j++) {
                  if (
                    results[i].address_components[j]?.types[0] == "route" ||
                    results[i].address_components[j]?.types[0] ==
                      "street_address" ||
                    results[i].address_components[j]?.types[0] == "premise"
                  ) {
                    window.test.street =
                      results[i].address_components[j].long_name;
                  }
                }
              }
              if (results[i]?.types[0] == "administrative_area_level_1") {
                for (let j = 0; j < results[i].address_components.length; j++) {
                  if (
                    results[i].address_components[j]?.types[0] ==
                    "administrative_area_level_1"
                  ) {
                    window.test.state =
                      results[i].address_components[j].long_name;
                  }
                }
              }
              if (results[i]?.types[0] == "postal_code") {
                for (let j = 0; j < results[i].address_components.length; j++) {
                  if (
                    results[i].address_components[j]?.types[0] == "postal_code"
                  ) {
                    window.test.zipCode =
                      results[i].address_components[j].long_name;
                  }
                }
              }
            }
          } else {
            window.alert("No results found");
          }
        }
      });
    },
    isEventWithinFiveDays(event) {
        if (!event?.startDate || !event?.endDate) return false;

        if (event && event.status === '2' && event.Url) {
            console.log("Skipping weather data fetch for online event.");
            return;
          }

        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const startDate = new Date(event.startDate);
        const endDate = new Date(event.endDate);
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(0, 0, 0, 0);

        // Calculate differences in days
        const daysFromTodayToStart = (startDate - today) / (1000 * 60 * 60 * 24);
        const daysFromTodayToEnd = (endDate - today) / (1000 * 60 * 60 * 24);

        // Check if event starts or ends within 5 days
        const startsWithinFiveDays = daysFromTodayToStart >= 0 && daysFromTodayToStart <= 5;
        const endsWithinFiveDays = daysFromTodayToEnd >= 0 && daysFromTodayToEnd <= 5;
        const ongoingToday = today >= startDate && today <= endDate;

        // **EXTRA CONDITION**: If event duration is too long, exclude it
        const eventDuration = (endDate - startDate) / (1000 * 60 * 60 * 24);
        if (eventDuration > 10) return false; // Example: Ignore events longer than 10 days

        return startsWithinFiveDays || endsWithinFiveDays || ongoingToday;
      },
    async getAttendee() {
      this.showEventMemeberCountShimmer = 1;
      var formData = new FormData();
      formData.append("event_id", this.singleEvent.id);
      await axios
        .post(
          process.env.VUE_APP_API_URL + "event/eventAttendeeListing",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          const userId = localStorage.getItem("userId");
          const eventAttendeeFromDB = response.data.data.attendeeListing;
          this.attendingList = response.data.data.attendeeListing;
          this.eventInvitedList = response.data.data.inviteListing;
          const tempMembers = this.selectedRoomTeam.users;
          const tempMembersRole = this.selectedRoomTeam.userRole;
          // this.Services = response.data.data.attendeeListing[0];
          const userInAttendeeList = this.attendingList.find(
            (user) => user.user_id == userId
          );
          if (userInAttendeeList) {
            this.Services = userInAttendeeList;
          }
          this.eventGoingForpdf = [];

          // Add Uniform ID to Going Members
          for (const memberRole of tempMembers) {
            for (const memberRoles of tempMembersRole) {
              if (memberRoles.id == memberRole.id && memberRoles.fan != "1") {
                for (const attendeeRole of eventAttendeeFromDB) {
                  if (
                    memberRole.c_id == attendeeRole.member_id &&
                    (attendeeRole.member_status == 1 ||
                      attendeeRole.member_status == "1")
                  ) {
                    const foundUser = tempMembersRole.find(
                      (user) => user?.id === memberRole.id
                    );
                    attendeeRole.uniform = foundUser.uniform;
                    this.eventGoingForpdf.push(attendeeRole);
                  }
                }
              }
            }
          }
          // Add Uniform ID to Going Members

          this.membersAttendeeComplete = [];
          var $i = 0;
          this.totalGoing = 0;
          this.totalNotGoing = 0;
          this.totalMayBe = 0;
          this.totalNotRespond = 0;
          for (const member of tempMembers) {
            for (const memberRole of tempMembersRole) {
              if (memberRole.id == member.id && memberRole.fan != "1") {
                this.membersAttendeeComplete.push(member);
                this.membersAttendeeComplete[$i].event_attendee_status = 0;
                for (const attendee of eventAttendeeFromDB) {
                  if (member.c_id == attendee.member_id) {
                    this.membersAttendeeComplete[$i].event_attendee_status =
                      attendee.member_status;
                    if (
                      this.membersAttendeeComplete[$i].event_attendee_status ==
                        1 ||
                      this.membersAttendeeComplete[$i].event_attendee_status ==
                        "1"
                    ) {
                      this.totalGoing++;
                    }
                    if (
                      this.membersAttendeeComplete[$i].event_attendee_status ==
                        2 ||
                      this.membersAttendeeComplete[$i].event_attendee_status ==
                        "2"
                    ) {
                      this.totalNotGoing++;
                    }
                    if (
                      this.membersAttendeeComplete[$i].event_attendee_status ==
                        3 ||
                      this.membersAttendeeComplete[$i].event_attendee_status ==
                        "3"
                    ) {
                      this.totalMayBe++;
                    }
                  }
                }

                if (
                  this.membersAttendeeComplete[$i].event_attendee_status == 0 ||
                  this.membersAttendeeComplete[$i].event_attendee_status == "0"
                ) {
                  this.totalNotRespond++;
                }

                if (member.id == this.currentUserId) {
                  this.currentUserEventStatus =
                    this.membersAttendeeComplete[$i].event_attendee_status;
                }
                $i++;
              }
            }
          }
          this.showEventMemeberCountShimmer = 0;
          this.showLoader = 1;
          // this.filterGoing();
          this.showLoader = 0;
        });
    },
    callModal: function () {
      this.$root.$emit("openEditEvent");
    },

    callRosterModal: function () {
      this.$root.$emit("openPrintRoasterModal");
      // document.querySelector("body").style.overflow = "hidden";
    },

    async generateTravelExcelFile() {
      try {
        var formData = new FormData();
        formData.append("event_id", this.singleEvent.id);
        const response = await axios.post(
          process.env.VUE_APP_API_URL + "event/eventAttendeeListing",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (
          response.data &&
          response.data.data &&
          response.data.data.attendeeListing
        ) {
          const eventAttendeeFromDB = response.data.data.attendeeListing;
          const tempMembers = this.selectedRoomTeam.users;
          const tempMembersRole = this.selectedRoomTeam.userRole;
          this.eventGoingForExcel = [];

          for (const memberRole of tempMembers) {
            for (const memberRoles of tempMembersRole) {
              if (memberRoles.id == memberRole.id && memberRoles.fan != "1") {
                for (const attendeeRole of eventAttendeeFromDB) {
                  if (
                    memberRole.c_id == attendeeRole.member_id &&
                    (attendeeRole.member_status == 1 ||
                      attendeeRole.member_status == "1")
                  ) {
                    const foundUser = tempMembersRole.find(
                      (user) => user?.id === memberRole.id
                    );
                    attendeeRole.uniform = foundUser.uniform;
                    this.eventGoingForExcel.push(attendeeRole);
                  }
                }
              }
            }
          }

          const workbook = new ExcelJS.Workbook();
          const worksheet = workbook.addWorksheet("EventAttendees");

          const headers = ["Name", "Phone/Email", "Travel Arrangements"];
          worksheet.addRow(headers).eachCell((cell) => {
            cell.font = { bold: true };
          });

          if (this.eventGoingForExcel.length === 0) {
            worksheet.addRow(["No One marked going yet!"]);
          } else {
            this.eventGoingForExcel.forEach((user) => {
              let services = user.services
                ? user.services.split(",").map((service) => service.trim())
                : [];
              let hotelServiceIndex = services.findIndex((service) =>
                service.toLowerCase().includes("hotel")
              );

              // Move the "Hotel" service to the beginning if it exists
              if (hotelServiceIndex !== -1) {
                const hotelService = services.splice(hotelServiceIndex, 1)[0];
                services.unshift(hotelService);
              }

              let servicesInfo = services.join(" / ");

              // Check if the hotel service exists and user has exactStartDate and exactEndDate
              if (
                hotelServiceIndex !== -1 &&
                user.exactStartDate &&
                user.exactEndDate
              ) {
                servicesInfo = `${servicesInfo.replace(
                  "Hotel",
                  `Hotel (${user.exactStartDate} - ${user.exactEndDate}) Room ${user.room_count}x Adults ${user.adult_count}x`
                )} /`;
              }
              const rowData = [
                user.fname && user.lname ? `${user.fname} ${user.lname}` : "",
                user.mob_code && user.mob && user.email
                  ? `${user.mob_code}${user.mob} ${user.email}`
                  : user.mob_code && user.mob && !user.email
                  ? `${user.mob_code}${user.mob}`
                  : !user.mob_code && user.mob && user.email
                  ? `${user.mob}${user.email}`
                  : user.mob_code && !user.mob && user.email
                  ? `${user.mob_code}${user.email}`
                  : !user.mob_code && !user.mob && user.email
                  ? `${user.email}`
                  : !user.mob_code && !user.mob && !user.email
                  ? "No contact information available"
                  : "",
                // user.services ? user.services.split(",").map(service => service.trim()).join(" / ") : '',
                servicesInfo,
              ];
              worksheet.addRow(rowData);
            });
          }

          workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const fileName = this.singleEvent.eventName;

            if (navigator.msSaveBlob) {
              navigator.msSaveBlob(blob, fileName);
            } else {
              const link = document.createElement("a");
              const url = URL.createObjectURL(blob);
              link.href = url;
              link.setAttribute("download", fileName);
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              URL.revokeObjectURL(url);
            }
            this.showEventMemeberCountShimmer = 0;
          });
        } else {
          console.error("Invalid response from the server");
          this.showEventMemeberCountShimmer = 0;
        }
      } catch (error) {
        console.error("Error generating Excel file:", error);
        this.showEventMemeberCountShimmer = 0;
      }
    },
    // showSchedule(teamId) {
    //   this.$router.push(`/game-events/${teamId}`);
    // },
    openEditEventOption() {
      this.editEventOption = !this.editEventOption;
    },
    onClickOutside() {
      this.editEventOption = false;
    },
    getEventAlert() {
      axios
        .get(`${process.env.VUE_APP_API_URL}getEventAlert`)
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.eventAlertOptions = response.data.data.alerts;
          } else {
            this.errorMessage = response.data.message;
          }
        });
    },
    openGoogleMap(item) {
      const strlatLng = item["lan"] + "," + item["long"];
      const urlSuffix = strlatLng
        ? strlatLng
        : item["address"] + ", " + item["city"] + ", " + item["zipCode"];

      window.open(
        "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
        "_blank"
      );
    },
    switchTabs(tab) {
      this.isActiveGames = tab === "games";
      this.isActiveAttendees = tab === "attendees";
      this.page = 1;
      this.lastPage = 1;
      if (tab === "games") {
        this.isActiveGames = true;
        this.isActiveAttendees = false;
      }
    },

    async fetchData() {
      this.showLoader = 1;
      var formData = new FormData();
      formData.append("event_id", this.$route.params.id);
      try {
        await axios
          .post(
            process.env.VUE_APP_API_URL +
              `event/details?page=${this.page}&size=${this.size}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            if (response.data.statusCode == 200) {
              this.singleEvent = response.data.data.details;
              this.marker.position = {
  lat: parseFloat(this.singleEvent.lan), // Convert "39.76366" to 39.76366
  lng: parseFloat(this.singleEvent.long) // Convert "-77.37545899999999" to -77.37545899999999
};

              this.games = response.data.data.games.data;
              if (this.games.length > 0) {
                this.getEventAlert();
              }
              this.lastPage = response.data.data.games.last_page;
              this.teamId = response.data.data.details.team_id;
              if (this.teamId) {
                this.getCurrentChatId();
                this.getTeamData();
              }
            }
          });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      this.showLoader = 0;
    },

    async fetchMoreGamesData() {
      this.showGameLoader = true;
      var formData = new FormData();
      formData.append("event_id", this.$route.params.id);
      try {
        await axios
          .post(
            process.env.VUE_APP_API_URL +
              `event/details?page=${this.page}&size=${this.size}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            if (response.data.statusCode == 200) {
              this.games = this.games.concat(response.data.data.games.data);
              this.lastPage = response.data.data.games.last_page;
            }
          });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      this.showGameLoader = false;
    },
    handleScrollToBottom(isVisible) {
      if (this.page >= this.lastPage) {
        return;
      }
      if (!isVisible) {
        return;
      }
      this.page++;
      this.fetchMoreGamesData();
    },
    async getCurrentChatId() {
      if (this.currentUserId == "") {
        const promiseUser = await firestoreService.getUserCid(this.userId);
        this.foundCurrentUser = promiseUser;
        if (this.foundCurrentUser.data[0].id != undefined) {
          this.currentUserId = this.foundCurrentUser.data[0].id;
        } else {
          this.getCurrentChatId();
        }
      }
    },
    async getTeamData() {
      const rawRoom = [];
      const promise = await firestoreService.getRoomById(this.teamId);
      rawRoom.push(promise);

      const foundRoom = await Promise.all(rawRoom);
      let usersData = {};
      Object.keys(foundRoom).forEach((key) => {
        const teamData = foundRoom[key];
        usersData = teamData.users;
        teamData.users = {};
        this.selectedRoomTeam = teamData;
      });

      // check role if team exits or user is member
      let roles = this.selectedRoomTeam.userRole;
      if (usersData != undefined) {
        var admincount = 0;
        for (const userRole of roles) {
          if (userRole.id == this.currentUserId) {
            admincount++;
          }
        }
        if (admincount == 0) {
          this.$router.push({ name: "PageNotFound" });
        }
      } else {
        this.$router.push({ name: "PageNotFound" });
      }

      await this.makeUserArray(usersData);
      const users = [];
      usersData.forEach((userId) => {
        const foundUser = this.allUsers.find((user) => user?._id === userId);
        if (foundUser) {
          var chechUrl;
          var url =
            this.$userProfileStorageURL +
            foundUser.c_id +
            "/profile_picture.jpg?v=" +
            this.time;

          this.urlExist(url, (exists) => {
            if (exists) {
              chechUrl = "yes";
              foundUser.avatar = url;
              console.log(chechUrl);
            } else {
              chechUrl = "no";
              foundUser.avatar = require("../../assets/images/user-default-new.png");
            }
          });
          users.push(foundUser);
        }
      });

      this.selectedRoomTeam.users = users;

      // assign admin role
      let tempMembers = this.selectedRoomTeam.users;

      // Set Users Role and Find if current User is admin
      for (const role of roles) {
        for (const member of tempMembers) {
          if (role.id == member.id) {
            member.adminRole = role.admin;
            member.fanRole = role.fan;
            member.roleStatus = role.status;
            member.uniform = role.uniform;
            member.bat_size = role.bat_size;
            member.bat_details = role.bat_details;
            member.hat_size = role.hat_size;
            member.shirt_size = role.shirt_size;
            member.pant_size = role.pant_size;
            var chechUrl;
            var url =
              this.$userProfileStorageURL +
              member.c_id +
              "/profile_picture.jpg?v=" +
              this.time;
            this.urlExist(url, (exists) => {
              if (exists) {
                chechUrl = "yes";
                member.avatar = url;
                console.log(chechUrl);
              } else {
                chechUrl = "no";
                member.avatar = require("../../assets/images/user-default-new.png");
              }
            });
          }
          if (member.id == this.currentUserId && member.adminRole == "1") {
            this.currentIsAdmin = "1";
          }
          if (member.id == this.currentUserId && member.adminRole == "0") {
            this.currentIsAdmin = "0";
          }
          if (member.id == this.currentUserId && member.fanRole == "1") {
            this.currentIsFan = "1";
          }
          if (member.id == this.currentUserId && member.fanRole != "1") {
            this.currentIsFan = "0";
          }

          // assign current user team status
          if (member.id == this.currentUserId && member.roleStatus == 1) {
            this.currentTeamIsActive = 1;
          }
          if (member.id == this.currentUserId && member.roleStatus == 2) {
            this.currentTeamIsActive = 2;
          }
        }
      }

      this.selectedRoomTeam.roomId = this.selectedRoomTeam.id;
    },
    async makeUserArray(users) {
      const roomUserIds = users;

      const rawUsers = [];
      roomUserIds.forEach((userId) => {
        const promise = firestoreService.getUser(userId);
        rawUsers.push(promise);
      });
      this.allUsers = [...this.allUsers, ...(await Promise.all(rawUsers))];
    },

    // URL exist ----------------------------------------------------------------
    urlExist(url, callback) {
      const img = new Image();
      img.src = url;

      if (img.complete) {
        callback(true);
      } else {
        img.onload = () => {
          callback(true);
        };

        img.onerror = () => {
          callback(false);
        };
      }
    },
  },
  mounted() {
    this.fetchData();
    this.$root.$on("showEventPageGames", () => {
      this.page = 1;
      this.lastPage = 1;
      this.fetchData();
      this.getAttendee();
    });
  },
};
</script>
<style>
.left-part-btns button.active {
  background-color: #e5f2fe !important;
  color: black;
}
</style>